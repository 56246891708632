<template>
  Estimated Sales Royalties
  <span
    v-tooltip="'Estimated net royalties (royalties after delivery / printing costs) earned from sales. This number is calculated when multiplying the Net Royalty Rate by the sales value.'"
    class="pi pi-info-circle mx-1"
  />
</template>

<script>
export default {};
</script>
